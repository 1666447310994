import React from 'react'
import AnimatedNumber from 'animated-number-react'
import cx from 'classnames'

import './number-diagram.scss'

const NumberDiagram = ({
  title,
  number,
  unit,
  children,
}) => {
  var sign = '-'
  const isPos = Math.sign(number)
  if (isPos === 1) {
    sign = '+'
  } else if (isPos === 0) {
    sign = '±'
  } else {
    sign = '-'
  }
  
  const classnames = cx({
    'number': true,
    'neutral': Math.sign(number) === 0
  })

  return (
    <div className='number-diagram'>
      <h3 className='title'>{title}</h3>
      <div className={classnames}>
        <span>{sign}</span>
        <AnimatedNumber
          duration={500}
          value={Math.abs((number))}
          formatValue={(value) => value.toLocaleString(undefined, {maximumFractionDigits:0})}
        />
        {unit && (<span>{` ${unit}`}</span>)}
      </div>
      {children && (
        <div className='number-diagram-info'>
          {children}
        </div>
      )}
    </div>
  )
}

export default NumberDiagram