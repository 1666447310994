import React from 'react'
import AnimatedNumber from 'animated-number-react'

import './number-description.scss'

const NumberDescription = ({
  number,
  unit
}) => {

  const isPositive = number >=0

  return (
    <div className='number-description'>
      {number && (
        <>
        {isPositive && '+'}
        <AnimatedNumber
          duration={500}
          value={number}
          formatValue={(value) => value.toLocaleString(undefined, {maximumFractionDigits:0})}
        />
        </>
      )}
      <span>&nbsp;{unit}</span>
    </div>
  )
}

export default NumberDescription