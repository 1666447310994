import React from 'react'

import './buttongroup.scss'
import ButtonGroupItem from './buttongroup-item/buttongroup-item'

const ButtonGroup = ({
  buttons,
  onSelected,
  metric,
}) => {

  const getTitle = (button) => {
    return ((metric && metric.id === 1) ? button.imperial : button.title)
  }

  return (
    <div className='buttongroup'>
      {buttons.map((button, index) => (
        <ButtonGroupItem
          key={index}
          title={getTitle(button)}
          value={button.value}
          selected={button.selected}
          onSelected={() => onSelected({button})}
          circle={button.circle}
        />
        
  ))}
    </div>
  )
}

export default ButtonGroup