import React from 'react'
import cx from 'classnames'
import AnimatedNumber from 'animated-number-react'

import './percentage-diagram.scss'

const PercentageDiagram = ({
  title,
  percentage,
  children,
}) => {
   const diagramValue = percentage/2
   const posOrNeg = Math.sign(diagramValue) > 0 ? 'positive' : 'negative'
   const classnames = cx({
     'bar': true,
     [`${posOrNeg}`]: true
   })

  const barWidth = {
    width: `${Math.abs(diagramValue)}%`
  }
  
  return (
    <div className='percentage-diagram'>
       <h3 className='title'>{title}</h3>
      <p className='percentage' key={percentage}>
        {Math.sign(diagramValue) > 0  ? '+' : '-'}
        <AnimatedNumber
          duration={500}
          value={Math.abs(percentage)}
          formatValue={(value) => value.toLocaleString(undefined, {maximumFractionDigits:0})}
        />
        {'%'}
      </p>
      <div className='diagram'>
        <div className={classnames} style={barWidth} />
        <div className='zero-line' />
      </div>
      {children && (
        <div className='percentage-diagram-info'>
          {children}
        </div>
      )}
    </div>
  )
}

export default PercentageDiagram