export const calculatorTexts = {
  calculatorTitle: 'Your current DTH set-up',
  calculatorSubtitle: 'Choose your current down-the-hole set-up and conditions.',
  resultsTitle: 'With Top Hammer XL',
  resultsSubtitle: 'Your potential annual improvements with Top Hammer XL system.',
  fuelTitle: 'Fuel consumption',
  capacityTitle: 'Productivity',
  carbonTitle: 'Co2 reduction',
  familyCars: 'family cars @ target ', /* whitespace matters! unit is added in code! */
  rigsTitle: 'No. rigs needed',
  rigsDescription: 'for target amount of ', /* whitespace matters! unit is added in code! */
}

export const countdownTexts = {
  productName: 'Top hammer XL',
  title: 'Launching in',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  calendarLink: 'Save the date',
  launched: 'It\'s here!'
}