import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Countdown from './components/countdown/countdown';
import Calculator from './components/calculator/calculator';
import './App.scss';


function App() {
  
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Calculator />
          </Route>
          <Route path="/countdown">
            <Countdown timeTillDate='2021-03-23T23:00:00Z' timeFormat='YYYY-MM-DDTHH:mm:ssZ' />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
