import React from 'react'

import './box.scss'

const Box = ({
  title,
  children,
}) => {
  return (
    <div className='calculator-box'>
      <p className='title'>{title}</p>
      {children}
    </div>
  )
}

export default Box