import React, { useState } from 'react'
import RCSlider from 'rc-slider';

import './slider.scss'

const Slider = ({
  values,
  onChange,
  selected
}) => {

  const [selectedValue, setSelectedValue] = useState(selected)

  const findMinMax = (arr) => {
    let min = values[0], max = values[0];
  
    for (let i = 1, len=values.length; i < len; i++) {
      let v = values[i];
      min = (v.step < min.step) ? v : min;
      max = (v.step > max.step) ? v : max;
    }
  
    return [min, max];
  }

  const onSliderChange = (value) => {
    const selectedValue = values.find(x => x.step === value)
    setSelectedValue(selectedValue)
    onChange(selectedValue)
  }

  const minmax = findMinMax(values)
  const sliderMarks = (<><p>{minmax[0].mark}</p><p>{minmax[1].mark}</p></>)
  
  return (
    <div className='slider'>
      <p className='slider-value' key={selected.mark}>
        {selected.mark}
      </p>
      <RCSlider
        dots
        step={1}
        defaultValue={selectedValue.step}
        min={0}
        max={(minmax[1].step)}
        onChange={onSliderChange}
      />
      <div className='slider-mark'>
        {sliderMarks}
      </div>
    </div>
  )
}

export default Slider



