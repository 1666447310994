import React from "react"
import moment from 'moment'
import { countdownTexts } from '../../data/texts'
import TagManager from 'react-gtm-module'

import './countdown.scss'

class Countdown extends React.Component {
  state = {
    hasPassed: false,
    days: undefined,
    hours: undefined,
    minutes: undefined,
  }

  updateCountdown(
    toDate,
    timeFormat) {
    const deadline = moment.utc(toDate, timeFormat);
    const now = moment.utc();
    const totalTime = deadline.diff(now);
    const minutes = Math.floor((totalTime / 1000 / 60) % 60);
    const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
    const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));
    const hasPassed = days + hours + minutes < 0;
    this.setState({ days, hours, minutes, hasPassed });
  }

  componentDidMount() {

    const tagManagerArgs = {
      gtmId: 'GTM-TZHVMVM'
    }

    TagManager.initialize(tagManagerArgs)
    const { timeTillDate, timeFormat } = this.props;
    this.updateCountdown(timeTillDate, timeFormat);
    this.interval = setInterval(() => {
        this.updateCountdown(timeTillDate, timeFormat);
    }, 60000);
  }

  componentWillUnmount() {
      if (this.interval) {
          clearInterval(this.interval);
      }
  }

  render () {
    const { days, hours, minutes, hasPassed } = this.state;
    if(hasPassed) {
      return (
        <div className='countdown'>
        <div className='countdown-headline'>
          <h3 className='countdown-headline--name'>{countdownTexts.productName}</h3>
          <h2 className='countdown-headline--launch'>{countdownTexts.launched}</h2>
        </div>
        </div>
      )
    } else {
      return (
        <div className='countdown'>
          <div className='countdown-headline'>
            <h3 className='countdown-headline--name'>{countdownTexts.productName}</h3>
            <h2 className='countdown-headline--launch'>{countdownTexts.title}</h2>
          </div>
          <div className='countdown-counter'>
            <div className='countdown-counter-item'>
              <span className='number'>{days}</span>
              <span className='unit'>{countdownTexts.days}</span>
            </div>
            <div className='countdown-counter-item'>
              <span className='number'>{hours}</span>
              <span className='unit'>{countdownTexts.hours}</span>
            </div>
            <div className='countdown-counter-item'>
              <span className='number'>{minutes}</span>
              <span className='unit'>{countdownTexts.minutes}</span>
            </div>
          </div>
          <div className='countdown-calendar'>
            <p>
              <a download href='../../data/tophammerxl-launch.ics' >
                {countdownTexts.calendarLink}
              </a>
            </p>
          </div>
        </div>
      )
    }
    }
}

export default Countdown