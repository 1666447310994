export const apiUrl = 'https://ewus0qw9db.execute-api.eu-west-1.amazonaws.com/test/calculate'

export const metrics = {
  title: 'System of measurement',
  options: [
  {
    id: 0,
    title: 'Metric',
    long: 'meters',
    short: 'm',
    drill: '(mm)',
    value: 1,
    mass: 1,
    massUnit: 'litres',
    weight: 1,
    weightUnit: 'kg',
  },
  {
    id: 1,
    title: 'Imperial',
    long: 'feet',
    drill: '(inch)',
    short: 'ft',
    value: 3.2808399,
    selected: true,
    mass: 0.21997,
    massUnit: 'gallons',
    weight: 2.20462,
    weightUnit: 'lb',
  }
]}

export const drillability = {
  title: 'Drillability of rock',
  options: [
    {
      id: 0,
      title: 'Hard',
      value: 35,
    },
    {
      id: 1,
      title: 'Medium',
      value: 43,
      selected: true,
    },
    {
      id: 2,
      title: 'Soft',
      value: 65,
    },
]}

export const diameter = {
  title: 'Your drill bit diameter ',
  options: [
    {
      id: 0,
      title: '140',
      value: 140,
      circle: true,
      imperial: '5.5',
    },
    {
      id: 1,
      title: '152',
      value: 152,
      circle: true,
      imperial: '6',
    },
    {
      id: 2,
      title: '165',
      value: 165,
      circle: true,
      selected: true,
      imperial: '6.5',
    },
    {
      id: 3,
      title: '172',
      value: 172,
      circle: true,
      imperial: '6.75',
    },
    {
      id: 4,
      title: '178',
      value: 178,
      circle: true,
      imperial: '7',
    },
]}

export const annualHours = {
  title: 'Your annual engine hours / rig',
  options: [
  {
    id: 0,
    step: 0,
    value: 1500,
    text: '1 500 hours',
    mark: '1 500 h',
  },
  {
    id: 1,
    step: 1,
    value: 2000,
    text: '2 000 hours',
    mark: '2 000 h',
  },
  {
    id: 2,
    step: 2,
    value: 3000,
    text: '3 000 hours',
    mark: '3 000 h',
    selected: true,
  },
  {
    id: 3,
    step: 3,
    value: 5000,
    text: '5 000 hours',
    mark: '5 000 h',
  },
]}

export const targetMeters = {
  title: 'Target amount of ',
  options: [
  {
    id: 0,
    step: 0,
    value: 45000,
    text: '45 000 m',
    mark: '45 000 m',
    metric: 0,
  },
  {
    id: 1,
    step: 1,
    value: 60000,
    text: '60 000 m',
    mark: '60 000 m',
    metric: 0,
    selected: true,
  },
  {
    id: 2,
    step: 2,
    value: 90000,
    text: '90 000 m',
    mark: '90 000 m',
    metric: 0,
  },
  {
    id: 3,
    step: 3,
    value: 160000,
    text: '160 000 m',
    mark: '160 000 m',
    metric: 0,
  },
  {
    id: 4,
    step: 4,
    value: 450000,
    text: '450 000 m',
    mark: '450 000 m',
    metric: 0,
  },
  {
    id: 5,
    step: 5,
    value: 500000,
    text: '500 000 m',
    mark: '500 000 m',
    metric: 0,
  },
  {
    id: 6,
    step: 0,
    value: 45000,
    text: '147,638 ft',
    mark: '147,638 ft',
    metric: 1,
  },
  {
    id: 7,
    step: 1,
    value: 60000,
    text: '196,850 ft',
    mark: '196,850 ft',
    metric: 1,
  },
  {
    id: 8,
    step: 2,
    value: 90000,
    text: '295,276 ft',
    mark: '295,276 ft',
    metric: 1,
  },
  {
    id: 9,
    step: 3,
    value: 160000,
    text: '524,934 ft',
    mark: '524,934 ft',
    metric: 1,
  },
  {
    id: 10,
    step: 4,
    value: 450000,
    text: '1,476,377 ft',
    mark: '1,476,377 ft',
    metric: 1,
  },
  {
    id: 11,
    step: 5,
    value: 500000,
    text: '1,640,420 ft',
    mark: '1,640,420 ft',
    metric: 1,
  },
]}