import React from 'react'
import cx from 'classnames'

import './buttongroup-item.scss'

const ButtonGroupItem = ({
  title,
  value,
  selected,
  circle,
  onSelected,
}) => {

  const classname = cx(
    'buttongroup-item',
    {'selected' : selected},
    {[`circle circle-${value}`]: circle}
  )

  return (
    <button
      className={classname}
      onClick={() => onSelected()}
    >
      <span className='inner' tabIndex='-1'>
        {title}
      </span>
    </button>
  )
}

export default ButtonGroupItem