import React, { useEffect, useState } from 'react'
import Box from './box/box'
import ButtonGroup from './buttongroup/buttongroup';
import NumberDescription from './number-description/number-description';
import NumberDiagram from './number-diagram/number-diagram';
import PercentageDiagram from './percentage-diagram/percentage-diagram';
import Slider from './slider/slider';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch'
import { calculatorTexts } from '../../data/texts'
import { metrics, drillability, diameter, annualHours, targetMeters, apiUrl } from '../../data/calculator-options'
import TagManager from 'react-gtm-module'
import './slider.scss'
import './calculator.scss'


const Calculator = () => {
  const [metricOptions, setMetricOptions] = useState(metrics.options)
  const [drillabilityOptions, setDrillabilityOptions] = useState(drillability.options)
  const [diameterOptions, setDiameterOptions] = useState(diameter.options)
  const [meters, setMeters] = useState(targetMeters.options.filter(x => x.metric === metrics.options.find(x => x.selected).id)[1])
  const [hours, setHours] = useState(annualHours.options[1])
  const [fuelPercent, setFuelPercent] = useState(0)
  const [fuelNumber, setFuelNumber] = useState(0)
  const [productivityPercent, setProductivityPercent] = useState(0)
  const [productivityNumber, setProductivityNumber] = useState(0)
  const [carbonReduction, setCarbonReduction] = useState(0)
  const [carIndex, setCarIndex] = useState(0)
  const [riggsNeeded, setRiggsNeeded] = useState(0)

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-KL46396'
    }

    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    const diameter = diameterOptions.find(x => x.selected).value
    const drillability = drillabilityOptions.find(x => x.selected).value
    const m = meters.value
    const h = hours.value
    let isSubscribed = true
    const urlParameters = `?diameter=${diameter}&meters=${m}&drillability=${drillability}&hours=${h}`
    fetch(apiUrl + urlParameters)
      .then((res) => res.json())
      .then(res => {  
        if (isSubscribed){
          setFuelPercent(res.fuelPercent)
          setFuelNumber(res.fuelNumber * metricOptions.find(x => x.selected).mass)
          setProductivityPercent(res.productivityPercent)
          setProductivityNumber(res.productivityNumber * metricOptions.find(x => x.selected).value)
          setCarbonReduction(res.carbonReduction * metricOptions.find(x => x.selected).weight)
          setCarIndex(res.carIndex)
          setRiggsNeeded(res.riggsNeeded)
        }
      })

      return () => isSubscribed = false
  }, [metricOptions, diameterOptions, drillabilityOptions, meters, hours])


  const updateMetric = ({button}) => {
    if(button.id !== metricOptions.find(x => x.selected).id) {
      const options = metricOptions.map(option => {
        const selected = option.id === button.id
        return {
          ...option,
          selected,
        }
      })
      const currentIndex = targetMeters.options.indexOf(meters)
      const numberOfmeterOptions = (targetMeters.options.length)/2
      const newIndex = currentIndex >= (numberOfmeterOptions) ? currentIndex - numberOfmeterOptions : currentIndex + numberOfmeterOptions

      setMetricOptions(options)
      setMeters(targetMeters.options[newIndex])
    }
  }

  const updateDrillability = ({button}) => {
    const options = drillabilityOptions.map(option => {
      const selected = option.id === button.id
      return {
        ...option,
        selected,
      }
    })

    setDrillabilityOptions(options)
  }

  const updateDiameter = ({button}) => {
    const options = diameterOptions.map(option => {
      const selected = option.id === button.id
      return {
        ...option,
        selected,
      }
    })

    setDiameterOptions(options)
  }

  const onHoursChange = value => {
    setHours(value)
  };

  const onMeterChange = value => {
    setMeters(value)
  }

  return (
    <>
    <div className='calculator'>
        <div className='selections'>
            <div className='calculator-info'>
              <h2>{calculatorTexts.calculatorTitle}</h2>
              <p className='description'>
                {calculatorTexts.calculatorSubtitle}
              </p>
            </div>
          <Box title={metrics.title}>
              <ButtonGroup
                buttons={metricOptions}
                onSelected={updateMetric}
              />
          </Box>
          <Box title={drillability.title}>
              <ButtonGroup
                buttons={drillabilityOptions}
                onSelected={updateDrillability}
              />
          </Box>
          <Box title={diameter.title + metricOptions.find(x => x.selected).drill}>
              <ButtonGroup
                buttons={diameterOptions}
                onSelected={updateDiameter}
                metric={metricOptions.find(x => x.selected)}
              />
          </Box>
          <Box title={annualHours.title}>
            <Slider
              values={annualHours.options}
              onChange={onHoursChange}
              selected={hours}
            />
          </Box>
          <Box title={targetMeters.title + metricOptions.find(x => x.selected).long}>
            <Slider
              values={targetMeters.options.filter(x => x.metric === metricOptions.find(x => x.selected).id)}
              onChange={onMeterChange}
              selected={meters}
            />
          </Box>
        </div>
        <div className='results'>
          <div
              className='wrapper'
            >
            <div className='results-info'>
              <h2>{calculatorTexts.resultsTitle}</h2>
              <p className='description'>
                {calculatorTexts.resultsSubtitle}
              </p>
            </div>
            <PercentageDiagram
              title={calculatorTexts.fuelTitle}
              percentage={fuelPercent}
            >
              <NumberDescription
              number={fuelNumber}
              unit={metricOptions.find(x => x.selected).massUnit + ' @ target ' + metricOptions.find(x => x.selected).long}
            />
            </PercentageDiagram>
            <PercentageDiagram
              title={calculatorTexts.capacityTitle}
              percentage={productivityPercent}
              >
                <NumberDescription
                number={productivityNumber}
                unit={metricOptions.find(x => x.selected).long + ' / year'}
              />
              </PercentageDiagram>
            <NumberDiagram
              title={calculatorTexts.carbonTitle}
              number={carbonReduction}
              unit={metricOptions.find(x => x.selected).weightUnit}
            >
              <NumberDescription
                unit={calculatorTexts.familyCars + metricOptions.find(x => x.selected).long}
                number={carIndex}
              />
            </NumberDiagram>
            <NumberDiagram
              title={calculatorTexts.rigsTitle}
              number={riggsNeeded}
              unit='rigs'
            >
              <NumberDescription
                unit={'(' + calculatorTexts.rigsDescription + metricOptions.find(x => x.selected).long + ')'}
              />
            </NumberDiagram>
            </div>
          </div>
      </div>
    </>
  )
}

export default Calculator